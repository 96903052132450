<template>
  <aside class="position-relative">
    <v-navigation-drawer
      permanent
      color="#273444"
      width="70"
      floating
      hide-overlay
      disable-resize-watcher
      disable-route-watcher
      touchless
      fixed
      app
    >
      <div class="logoTop d-flex justify-center align-center">
        <router-link to="/">
          <img :src="imgLogo" />
        </router-link>
      </div>
      <div class="sidebarMenuList">
        <v-list dense nav class="px-0 pt-0 pb-0">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.route"
            :disabled="item.disabled"
            class="itemMenuSidebar"
          >
            <v-list-item-icon
              class="
                iconMenuSidebard-flex
                flex-column
                justify-content-center
                pt-5
              "
            >
              <v-icon size="20">{{ item.icon }}</v-icon>
              <span class="font10pt mt-2 text-center menutext">
                {{ item.title }}
              </span>
            </v-list-item-icon>
            <div v-if="item.submenu">
              <v-menu
                open-on-hover
                attach="aside"
                allow-overflow
                offset-x
                content-class="floatMenu elevation-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="submenu-dashboard position-absolute"
                  ></div>
                </template>
                <v-list class="sublink">
                  <v-list-item
                    v-for="(item, index) in item.submenu"
                    :key="index"
                    link
                    :to="item.link"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {
      items: [
        {
          title: "Dashboard",
          name: "dashboard",
          icon: "fas fa-tachometer-alt",
          route: "/dashboard",
          submenu: [
            { title: "Your Boards", link: "/dashboard/your-boards" },
            { title: "ISC Boards", link: "/dashboard/isc-boards" },
          ],
          disabled: false,
        },
        {
          title: "Overview",
          name: "overview",
          icon: "fas fa-eye",
          route: "/overview",
          disabled: false,
        },
        {
          title: "Clustering Analysis",
          name: "clustering-analysis",
          icon: "fas fa-spinner",
          route: "/clustering-analysis",
          disabled: false,
        },
        {
          title: "GEO Listening",
          name: "geolistening",
          icon: "fas fa-map-marker",
          route: "/geo-listening",
          disabled: false,
        },
      ],
      right: null,
      imgLogo: null,
    };
  },
  computed: {
    ...mapGetters("global", {
      context: "getContextBoard",
      stateMenuItem: "getDisabledMenuItem",
    }),
    /* items() {
      return this.over;
    }, */
  },
  created() {},
  mounted() {
    this.setContextBoard("");
    this.changeUrlFunction(this.$route);
    this.imgLogo = require("@/assets/icons/logo.png");
  },
  methods: {
    ...mapActions("global", ["setContextBoard"]),
    changeUrlFunction(e) {
      /*console.log('url', this.stateMenuItem);*/

      let overviewItem = this.items.find((x) => x.name == "overview");
      let clusteringItem = this.items.find(
        (x) => x.name == "clustering-analysis"
      );
      // eslint-disable-next-line no-console
      if (this.context !== "") {
        overviewItem.route = `/${overviewItem.name}/${this.context}`;
        clusteringItem.route = `/${clusteringItem.name}/${this.context}`;
      } else {
        const urlPath = e.path;
        let contextParam;
        if (
          urlPath.includes("/overview/") ||
          urlPath.includes("/clustering-analysis/")
        ) {
          contextParam = e.path
            .replace("/overview/", "")
            .replace("/clustering-analysis/", "");
        }
        if (contextParam) {
          overviewItem.route = `/${overviewItem.name}/${contextParam}`;
          clusteringItem.route = `/${clusteringItem.name}/${contextParam}`;
        } else {
          overviewItem.disabled = true;
          clusteringItem.disabled = true;
        }
      }
    },
  },
  watch: {
    stateMenuItem(e) {
      let overviewItem = this.items.find((x) => x.name == "overview");
      let clusteringItem = this.items.find(
        (x) => x.name == "clustering-analysis"
      );
      overviewItem.disabled = e;
      clusteringItem.disabled = e;
    },
    $route(to, from) {
      /*console.log('route', to);*/
      this.changeUrlFunction(to);
    },
  },
};
</script>

<style>
.v-list-item__icon span {
  font-size: 10px;
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 0;
}
.logoTop {
  height: 60px;
}
.logoTop img {
  width: 35px;
  height: 40px;
  position: relative;
  left: -3px;
  top: 1px;
}
.itemMenuSidebar {
  min-height: 90px !important;
  border-top: 1px solid #464c5e;
  padding: 0 0 !important;
  border-right: 3px solid transparent;
  margin: 0px !important;
}
.itemMenuSidebar .v-list-item__icon {
  min-height: 90px !important;
  margin: 0 !important;
  width: 70px;
  /*padding-top: 20px;*/
}
.itemMenuSidebar .v-icon {
  color: #b0b8c4 !important;
}
.itemMenuSidebar:hover,
.itemMenuSidebar.v-list-item--active {
  border-right: 3px solid #00a5ff;
  background-color: #162938;
}
.itemMenuSidebar:hover .v-icon,
.itemMenuSidebar:hover .menutext {
  color: #00a5ff !important;
}
.floatMenu {
  border-radius: 0 !important;
  border-left: 5px solid transparent;
  min-width: 130px !important;
  top: 60px !important;
  position: fixed !important;
  text-align: center;
}
.submenu-dashboard {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.sublink {
  background-color: #273444 !important;
  padding: 0px !important;
  border-radius: 0 !important;
}
.sublink
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}
.sublink .v-list-item--link {
  padding-right: 50px;
}
.sublink .v-list-item--link:last-child {
  border-top: 1px solid #464c5e;
}
.sublink .v-list-item--link:hover,
.sublink .v-list-item--active {
  color: #fff !important;
  background-color: #00a5ff;
}
.sublink .v-list-item--active:before {
  opacity: 0;
}
.v-list-item--disabled .v-icon {
  color: #464c5e !important;
}
.v-list-item--disabled span.menutext {
  color: #464c5e !important;
}
</style>