<template>
  <div>
    <div id="sidebar">
      <Sidebar />
      <!-- <router-view /> -->
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
export default {
  name: "Navigation",
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
/* #sidebar{
    position: absolute !important;
  } */
</style>
